<template>
  <nav id="desktop-nav">
    <div class="logo">
      <router-link to="/"
        ><img src="../assets/ooo-interiors.png" alt=""
      /></router-link>
    </div>
    <ul class="nav-links">
      <li><router-link to="/services">Services</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>

    <div class="lets-talk">
      <router-link to="/#">LETS TALK</router-link>
    </div>
  </nav>
  <nav id="hamburger-nav">
    <div class="logo">
      <router-link to="/"
        ><img src="../assets/ooo-interiors.png" alt=""
      /></router-link>
    </div>
    <div class="hamburger-menu">
      <div class="menu-icon" @click="toggleMenu" :class="{ open: isOpen }">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
      <div v-if="isOpen" class="menu-items">
        <ul>
          <li>
            <router-link to="/services" @click="toggleMenu"
              >Services</router-link
            >
          </li>
          <li>
            <router-link to="/about" @click="toggleMenu">About</router-link>
          </li>
          <li>
            <router-link to="contact" @click="toggleMenu">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section class="services-we-provide">
    <div class="service-content">
      <h1>Services we provide</h1>
      <div class="service-content-container">
        <div class="card">
          <h3>Labourer Services</h3>
          <p>
            We provide skilled and reliable labourers for building sites,
            ensuring your projects run smoothly and efficiently.
          </p>
        </div>
        <div class="card">
          <h3>Taping & Jointing</h3>
          <p>
            Our skilled taping and jointing experts deliver seamless and smooth
            finishes to your interior walls and ceilings, ensuring a
            professional and lasting result.
          </p>
        </div>
        <div class="card">
          <h3>Plastering Solutions</h3>
          <p>
            Our plastering solutions deliver a smooth and polished finish to
            your interior and exterior walls, adding a touch of elegance and
            sophistication to your building.
          </p>
        </div>
        <div class="card">
          <h3>Additional Service 1</h3>
          <p>This service is about something</p>
        </div>
      </div>
    </div>
  </section>
  <section class="projects">
    <div class="project-content">
      <div>
        <img src="../assets/labourer.webp" alt="" />
      </div>

      <div class="column">
        <h1>Labourer Services</h1>
        <p>
          At ooo interiors, we understand the importance of having a skilled and
          reliable workforce. That’s why we provide labourers for building
          sites, ensuring your projects run smoothly and efficiently. Our
          labourers are experienced, trained, and equipped with the necessary
          tools to complete any task assigned to them. We work with you to
          understand your labour requirements and provide the right personnel
          that fit your project needs.
        </p>
        <router-link to="/#" class="read-more">Read more</router-link>
      </div>

      <div class="column">
        <h1>Taping and Joining</h1>
        <p>
          At ooo interiors, we believe that the finishes on your interior walls
          and ceilings play a vital role in the overall appearance of your
          building. That’s why we have a team of skilled taping and joining
          experts who deliver seamless and smooth finishes that stand the test
          of time. We use the latest techniques and tools to ensure each job is
          completed to the highest standards, ensuring your satisfaction and
          delivering a professional and lasting result.
        </p>
        <router-link to="/#" class="read-more">Read more</router-link>
      </div>
      <div>
        <img src="../assets/taping.webp" alt="" />
      </div>
      <div>
        <img src="../assets/plastering.webp" alt="" />
      </div>
      <div class="column">
        <h1>Plastering Solutions</h1>
        <p>
          At ooo interiors, we believe that the finish on your walls is just as
          important as the structure itself. That’s why we have a team of
          experts who specialize in delivering plastering solutions that add a
          touch of elegance and sophistication to your building. Our plastering
          solutions are designed to deliver a smooth and polished finish that
          stands the test of time, ensuring your satisfaction and adding value
          to your property.
        </p>
        <router-link to="/#" class="read-more">Read more</router-link>
      </div>
    </div>
  </section>
  <section class="services-footer">
    <span class="social-media-images">
      <img src="../assets/facebook.png" alt="" />
      <img src="../assets/twitter.png" alt="" />
      <img src="../assets/instagram.png" alt="" />
      <img src="../assets/youtube.png" alt="" />
    </span>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.projects {
  width: 100%;
  height: auto;
  background-color: #f4f8fc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  margin-top: 6rem;
  margin-bottom: 4rem;
  grid-gap: 6rem 0;
}
.project-content img {
  width: 100%;
  height: auto;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 5rem;
}

.column h1 {
  font-size: 2rem;
  text-align: left;
  margin-bottom: 2rem;
  color: #212121;
  font-weight: 400;
  line-height: 1.2;
}
.column p {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 2rem;
}
.services-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  background-color: white;
  padding-block: 6rem;
}

@media (max-width: 768px) {
  .project-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 6rem auto;
  }
  .column {
    padding: 2.5rem;
  }
  .project-content img {
    width: 100%;
    height: auto;
  }
  .column h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
/* New media query for screens up to 480px (typical smartphones in portrait mode) */
@media (max-width: 480px) {
  .project-content {
    margin: 4rem auto;
  }
  .column {
    padding: 1.5rem;
  }
  .column h1 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 1024px) {
  .hero-content {
    width: 80%; /* Adjust the width as needed */
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .project-content {
    flex-direction: column;
    align-items: center;
    width: 80%; /* Adjust the width as needed */
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .project-content .column {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem; /* Adjust spacing between project items */
  }
  .column h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
</style>
