<template>
  <nav id="desktop-nav">
    <div class="logo">
      <router-link to="/"
        ><img src="../assets/ooo-interiors.png" alt=""
      /></router-link>
    </div>
    <ul class="nav-links">
      <li><router-link to="/services">Services</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>

    <div class="lets-talk">
      <router-link to="/#">LETS TALK</router-link>
    </div>
  </nav>
  <nav id="hamburger-nav">
    <div class="logo"><img src="../assets/ooo-interiors.png" alt="" /></div>
    <div class="hamburger-menu">
      <div class="menu-icon" @click="toggleMenu" :class="{ open: isOpen }">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
      <div v-if="isOpen" class="menu-items">
        <ul>
          <li>
            <router-link to="/services" @click="toggleMenu"
              >Services</router-link
            >
          </li>
          <li>
            <router-link to="/about" @click="toggleMenu">About</router-link>
          </li>
          <li>
            <router-link to="/contact" @click="toggleMenu">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section class="hero-container">
    <div class="hero-content">
      <h1 class="hero-h1">
        Your Trusted Partner for Building Site Laborers & Finishing Solutions
      </h1>
      <p>
        Welcome to ooo Interiors, your trusted partner. We provide exceptional
        labourer services, specializing in taping and joining as well as
        plastering solutions. Our commitment to quality, efficiency, and safety
        sets us apart in the industry. Our team of experienced professionals is
        dedicated to delivering unparalleled results for all your building
        needs.
      </p>
      <img src="../assets/hero-image.webp" alt="" />
    </div>
  </section>
  <section class="services-we-provide">
    <div class="service-content">
      <h1>Services we provide</h1>
      <div class="service-content-container">
        <div class="card">
          <h3>Labourer Services</h3>
          <p>
            We provide skilled and reliable labourers for building sites,
            ensuring your projects run smoothly and efficiently.
          </p>
        </div>
        <div class="card">
          <h3>Taping & Jointing</h3>
          <p>
            Our skilled taping and jointing experts deliver seamless and smooth
            finishes to your interior walls and ceilings, ensuring a
            professional and lasting result.
          </p>
        </div>
        <div class="card">
          <h3>Plastering Solutions</h3>
          <p>
            Our plastering solutions deliver a smooth and polished finish to
            your interior and exterior walls, adding a touch of elegance and
            sophistication to your building.
          </p>
        </div>
        <div class="card">
          <h3>Additional Service 1</h3>
          <p>This service is about something</p>
        </div>
      </div>
    </div>
  </section>
  <section class="projects">
    <div class="project-content">
      <div class="column">
        <h1>Grandview Tower: Seamless Drywalling Solutions</h1>
        <p>
          Learn how ooo interiors’ expert drywalling services helped Grandview
          Tower streamline its construction process, reducing project timelines
          and minimizing costs.
        </p>
        <router-link to="/#" class="read-more">Read more</router-link>
      </div>
      <div>
        <img src="../assets/wall-4.jpeg" alt="" />
      </div>

      <div>
        <img src="../assets/wall-2.jpeg" alt="" />
      </div>
      <div class="column">
        <h1>Parkwood Estate: Comprehensive Plastering Solutions</h1>
        <p>
          Discover how ooo interiors’ comprehensive plastering solutions enabled
          Parkwood Estate to achieve a sophisticated, polished look for its
          high-end residential project, enhancing the overall value and appeal
          of the development.
        </p>
        <router-link to="/#" class="read-more">Read more</router-link>
      </div>
    </div>
  </section>
  <section class="subscribe">
    <div class="subscribe-content">
      <h1>Subscribe to Newsletter</h1>
      <p>
        Enter your email address to stay up to date with our latest news and
        updates
      </p>
      <input type="email" placeholder="Enter your email" />
      <button class="btn">Subscribe</button>
    </div>
  </section>
  <section class="home-footer">
    <span class="social-media-images">
      <img src="../assets/facebook.png" alt="" />
      <img src="../assets/twitter.png" alt="" />
      <img src="../assets/instagram.png" alt="" />
      <img src="../assets/youtube.png" alt="" />
    </span>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: white;
  color: rgb(85, 85, 85);
  font-size: 1rem;
  line-height: 1.6;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}
p {
  color: rgb(85, 85, 85);
}
a,
.btn {
  transition: all 300ms ease;
}
nav,
ul {
  display: flex;
}
.menu-items ul {
  display: flex;
  flex-direction: column;
}
nav {
  justify-content: space-between;
  align-items: center;
  height: 17vh;
  background-color: #ffffff;
}
ul {
  list-style: none;
  gap: 2rem;
  font-size: 1.2rem;
}
li {
  text-decoration: none;
  color: black;

  margin-inline: 2rem;
}
li:hover {
  color: grey;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: rgb(181, 181, 181);
  cursor: pointer;
}
.logo img {
  width: 150px;
  height: 150px;
  margin-left: 2rem;
}
.logo:hover {
  cursor: default;
}
.nav-links {
  display: flex;
  margin-left: 40rem;
}
.nav-links a {
  text-decoration: none;
  color: black;
}

.lets-talk {
  width: 166px;
  height: 51px;
  background-color: #236fe1;
  font-size: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 2rem;
  cursor: pointer;
}
.lets-talk a {
  text-decoration: none;
  color: white;
}
/* Hamburger Menu */
#hamburger-nav {
  display: none;
}
.hamburger-menu {
  position: relative;
}

.menu-icon {
  cursor: pointer;
  padding-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  transition: transform 0.3s ease-in-out;
}

.menu-items {
  position: absolute;
  top: 100%; /* Adjust as per your design */
  right: 0;
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  widows: fit-content;
  min-height: 0;
}

.menu-items ul {
  list-style: none;
  padding: 0;
}

.menu-items ul li {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.menu-items ul li a {
  text-decoration: none;
  color: #333;
}

/* Styles for the "X" shape when menu is open */
.menu-icon.open .bar1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon.open .bar2 {
  opacity: 0;
}

.menu-icon.open .bar3 {
  transform: rotate(-45deg) translate(5px, -5px);
}
/* MEDIA QUERRIES */

@media screen and (max-width: 1200px) {
  #desktop-nav {
    display: none;
  }
  #hamburger-nav {
    display: flex;
  }
}

.hero-container {
  width: 100%;
  height: auto;
  background-color: #f4f8fc;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding: 2rem;
}
.hero-content .hero-h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: black;
  font-weight: bold;
}
.hero-content p {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}
.hero-content img {
  width: 100%;
  height: 647px;
}
.services-we-provide {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(85, 85, 85);
}
.service-content {
  width: 60%;
  margin-top: 3rem;
  margin-bottom: 4rem;
  padding: 2rem;
}
.service-content h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
  line-height: 1.2;
  color: #212121;
  display: flex;
  justify-content: left;
}
.service-content-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.card {
  background-color: #f3f4f6;
  padding: 2rem;
  border-radius: 5px;
  transition: transform 0.3s ease;
}
.card:hover {
  transform: translateY(-10px);
}
.card h3 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: black;
}
.card p {
  font-size: 1rem;
}
.projects {
  width: 100%;
  height: auto;
  background-color: #f4f8fc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 60%;
  margin-top: 6rem;
  margin-bottom: 4rem;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 2rem;
}
.column h1 {
  font-size: 2.5rem;
  text-align: left;
  margin-bottom: 2rem;
  color: black;
  font-weight: bold;
  line-height: 1.2;
}
.column p {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 2rem;
}
.read-more {
  font-size: 14px;
  color: white;
  background-color: #236fe1;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 2rem;
  margin-left: 0;
  text-align: center;
  display: inline-block;
  width: 150px;
  height: 40px;
  text-transform: uppercase;
}
.subscribe {
  width: 100%;
  height: auto;
  background-color: #2a3030;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subscribe-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
}

.subscribe-content h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  font-weight: bold;
}
.subscribe-content p {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
}
input {
  width: 60%;
  height: 40px;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 2rem;
}
.btn {
  width: 150px;
  height: 40px;
  background-color: #236fe1;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.home-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  background-color: white;
  padding-block: 6rem;
}
@media screen and (max-width: 768px) {
  #desktop-nav {
    display: none;
  }
  #hamburger-nav {
    display: flex;
  }
  .hero-content {
    width: 100%;
  }
  .hero-content img {
    width: 100%;
    height: auto;
  }
  .hero-content .hero-h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: black;
    font-weight: bold;
  }

  .services-we-provide {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(85, 85, 85);
  }
  .service-content {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 4rem;
    padding: 2rem;
  }
  .service-content h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
    line-height: 1.2;
    color: #212121;
    display: flex;
    justify-content: left;
  }
  .service-content-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }
  .project-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 6rem auto;
  }
  .column {
    padding: 2.5rem;
  }
  .column h1 {
    font-size: 11.5rem;
    margin-bottom: 1rem;
  }
  .project-content img {
    width: 100%;
    height: auto;
  }
  .subscribe {
    width: 100%;
    height: auto;
    background-color: #2a3030;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subscribe-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }
  input {
    width: 100%;
  }

  .subscribe-content h1 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .hero-content {
    width: 80%; /* Adjust the width as needed */
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .project-content {
    flex-direction: column;
    align-items: center;
    width: 80%; /* Adjust the width as needed */
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .project-content .column {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem; /* Adjust spacing between project items */
  }
  .column h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

</style>
