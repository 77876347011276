<template>
  <nav id="desktop-nav">
    <div class="logo">
      <router-link to="/"
        ><img src="../assets/ooo-interiors.png" alt=""
      /></router-link>
    </div>
    <ul class="nav-links">
      <li><router-link to="/services">Services</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>

    <div class="lets-talk">
      <router-link to="/#">LETS TALK</router-link>
    </div>
  </nav>
  <nav id="hamburger-nav">
    <div class="logo"><img src="../assets/ooo-interiors.png" alt="" /></div>
    <div class="hamburger-menu">
      <div class="menu-icon" @click="toggleMenu" :class="{ open: isOpen }">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
      <div v-if="isOpen" class="menu-items">
        <ul>
          <li>
            <router-link to="/services" @click="toggleMenu"
              >Services</router-link
            >
          </li>
          <li>
            <router-link to="/about" @click="toggleMenu">About</router-link>
          </li>
          <li>
            <router-link to="/contact" @click="toggleMenu">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section class="contact">
    <div class="contact-content">
      <div>
        <h1>Get in touch with us</h1>
        <p>
          Thank you for considering ooo interiors for your labourer and
          construction needs. Please feel free to reach out to us with any
          questions or inquiries you may have. We are committed to providing
          exceptional services to our clients, and we take pride in our ability
          to exceed their expectations. You can contact us via phone, email, or
          by filling out the form on this page. We look forward to hearing from
          you soon.
          <span class="social-media-images">
            <img src="../assets/facebook.png" alt="" />
            <img src="../assets/twitter.png" alt="" />
            <img src="../assets/instagram.png" alt="" />
            <img src="../assets/youtube.png" alt="" />
          </span>
        </p>
      </div>
      <div class="info">
        <form
          @submit.prevent="submitForm"
          method="post"
          action="https://formspree.io/f/mdoqjoge"
        >
          <label for="name">Name</label>
          <input type="text" name="text" v-model="formData.name" required />
          <label for="email">Email</label>
          <input type="email" name="email" v-model="formData.email" required />
          <label for="subject">Subject</label>
          <input
            type="text"
            name="subject"
            v-model="formData.subject"
            required
          />
          <label for="message">Message</label>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            v-model="formData.message"
            required
          ></textarea>
          <button>Submit</button>
        </form>
        <div v-if="showSuccessMessage" class="success-message">
          <p>Form submitted successfully!</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      errorMessages: "",
      showSuccessMessage: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    async submitForm() {
      try {
        const response = await fetch("https://formspree.io/f/mdoqjoge", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });

        if (response.ok) {
          // Form submitted successfully, show success message and clear form data
          this.showSuccessMessage = true;
          this.clearFormData();
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 3000); // Hide success message after 3 seconds
        } else {
          // Error handling
          console.error("Form submission failed");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
    clearFormData() {
      // Clear form data
      this.formData.name = "";
      this.formData.subject = "";
      this.formData.message = "";
      this.formData.email = "";
    },
  },
};
</script>
<style>
.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  padding: 10px;
  margin-top: 10px;
}
.contact {
  width: 100%;
  height: auto;
  margin: 6rem auto;
  margin-bottom: 4rem;
  margin-top: 0;
  background-color: #fafafa;
}
.contact-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  padding: 5rem;
  margin: 0 auto;
  grid-gap: 6rem;
}
.contact-content h1 {
  font-size: 2.5rem;
  text-align: left;
  margin-bottom: 2rem;
  color: #212121;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}
.info label {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #212121;
}
.info input {
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #212121;
}
.info textarea {
  width: 100%;
  height: 12rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #212121;
}
.info button {
  width: 40%;
  height: 2.5rem;
  background-color: #236fe1;
  color: #fff;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .contact-content {
    grid-template-columns: 1fr;
    padding: 2rem;
  }
  .info button {
    width: 100%;
  }
  .contact-content h1 {
    font-size: 2rem;
  }
}
</style>
