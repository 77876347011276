<template>
  <nav id="desktop-nav">
    <div class="logo">
      <router-link to="/"
        ><img src="../assets/ooo-interiors.png" alt=""
      /></router-link>
    </div>
    <ul class="nav-links">
      <li><router-link to="/services">Services</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>

    <div class="lets-talk">
      <router-link to="/#">LETS TALK</router-link>
    </div>
  </nav>
  <nav id="hamburger-nav">
    <div class="logo"><img src="../assets/ooo-interiors.png" alt="" /></div>
    <div class="hamburger-menu">
      <div class="menu-icon" @click="toggleMenu" :class="{ open: isOpen }">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
      <div v-if="isOpen" class="menu-items">
        <ul>
          <li>
            <router-link to="/services" @click="toggleMenu"
              >Services</router-link
            >
          </li>
          <li>
            <router-link to="/about" @click="toggleMenu">About</router-link>
          </li>
          <li>
            <router-link to="contact" @click="toggleMenu">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section class="about">
    <h1>About</h1>
  </section>
  <section class="our-vision">
    <h3>Our Vision</h3>
    <p>
      At ooo interiors, we aspire to become the leading subcontractor in the
      construction industry, providing superior services to clients across the
      country. We’re committed to delivering quality workmanship and exceptional
      customer service while ensuring the safety of our team and clients. Our
      vision is to transform the construction industry with innovative solutions
      and affordable pricing. Through our expertise, commitment, and passion for
      excellence, we aim to exceed our clients’ expectations and build
      long-lasting relationships.

      <span> Let’s talk </span>
      <span>
        <a href="mailto:Tonywilliams@ooo-interiors.co.uk"
          >hr@ooo-interiors.com</a
        >
      </span>
      <span class="social-media-images">
        <img src="../assets/facebook.png" alt="" />
        <img src="../assets/twitter.png" alt="" />
        <img src="../assets/instagram.png" alt="" />
        <img src="../assets/youtube.png" alt="" />
      </span>
    </p>
  </section>
  <section class="team-story">
    <div>
      <img src="../assets/team.webp" alt="" />
    </div>
    <div class="about-column">
      <h3>Team Story</h3>
      <p>
        At ooo interiors, we’re a team of skilled and experienced subcontractors
        with a passion for delivering quality workmanship. We take pride in our
        work and ensure that every project we undertake is completed to the
        highest standards. Our team is reliable, efficient, and committed to
        providing exceptional services. With a focus on safety and
        affordability, we strive to exceed our clients’ expectations and build
        long-lasting relationships. Join us on our journey to transform
        construction sites with our expertise and dedication.
      </p>
    </div>
  </section>
  <section class="footer">
    <span class="social-media-images">
      <img src="../assets/facebook.png" alt="" />
      <img src="../assets/twitter.png" alt="" />
      <img src="../assets/instagram.png" alt="" />
      <img src="../assets/youtube.png" alt="" />
    </span>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style>
.about {
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  height: 25vh;
  background-color: #f4f8fc;
}
.about h1 {
  margin-left: 15%;
}
.our-vision {
  width: 60%;
  margin-left: 15%;
  margin-top: 2rem;
}
.our-vision h3 {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: #236fe1;
}
.our-vision p {
  font-size: 1rem;
  color: #212121;
}
.our-vision span {
  display: block;
  margin-top: 2rem;
}
.our-vision a {
  color: #236fe1;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
}
.social-media-images {
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-top: 2rem;
}
.social-media-images img {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.5rem;
}
.team-story {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  margin: 6rem auto;
  margin-bottom: 4rem;
}
.team-story h3 {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: #236fe1;
}
.team-story p {
  font-size: 1rem;
  color: #212121;
}
.team-story img {
  width: 100%;
  height: auto;
}
.about-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 2.5rem;
  grid-gap: 1rem;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  background-color: #f4f8fc;
  padding-block: 6rem;
}
@media (max-width: 768px) {
  .our-vision {
    width: 90%;
    margin-left: 5%;
  }
  .team-story {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 6rem auto;
  }
  .about-column {
    padding: 2.5rem;
  }
  .social-media-images {
    width: 50%;
  }
}
</style>
